import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom';
import { FaGithub, FaExternalLinkAlt } from "react-icons/fa";
import SectionHeading from '../SectionHeading/SectionHeading';
import Loader from '../Loader/Loader';
import { projectsData } from '../../../Data';
import Page404 from '../404/Page404';
import PageWrapper from "../PageWrapper";
import "./SingleProject.scss"

const SingleProject = () => {
    const { projectName } = useParams();
    const [crntProject, setCrntProject] = useState(null);
    const [isLoader, setIsLoader] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setIsLoader(false);
        }, 2000);
    }, []);

    useEffect(() => {
        const filteredProjects = projectsData.filter(item => item.projectName === projectName.toString());

        if (filteredProjects.length > 0) {
            setCrntProject(filteredProjects[0]);
        }
    }, [projectName]);

    return (
        <>
            {isLoader ? <Loader /> : (
                <PageWrapper>
                    {crntProject ? (


                        <div className='single-p'>
                            <div className='bgAttachment' style={{ backgroundImage: `url(${crntProject.layoutImg})` }} />
                            <div className="height-80"></div>

                            <section className='single-p__hero'>
                                <div className="single-p__hero-wrap">
                                    <div className="container">
                                        <div className="single-p__hero-text">
                                            <h1> {crntProject.title} </h1>
                                            <h2> {crntProject.subtitle} </h2>
                                            <div className="single-p__hero-btns">
                                                <div className="social-link">
                                                    <Link
                                                        to={crntProject.demoLink}
                                                        className="social-btn active"
                                                        target="_blank"
                                                    >
                                                        <span className="social-icon"><FaExternalLinkAlt /></span>
                                                        <span className="icon-name">WebSite</span>
                                                    </Link>
                                                    <Link
                                                        to={crntProject.gitHubLink}
                                                        className="social-btn active"
                                                        target="_blank"
                                                    >
                                                        <span className="social-icon"><FaGithub /></span>
                                                        <span className="icon-name">GitHub</span>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="single-p__hero-img">
                                    <img
                                        src={`${crntProject.headerImg}`}
                                        alt="hero-img"
                                        data-aos="fade-left"
                                        data-aos-delay="600"
                                        data-aos-duration="1000"
                                    />
                                </div>
                            </section>

                            <section className='single-p__concept text-center'>
                                <SectionHeading title="concept" />
                                <div className="container">
                                    <p>{crntProject.concept}</p>
                                </div>
                            </section>

                            <section className='single-p__technologies'>
                                <SectionHeading title="made with" />
                                <div className="container">
                                    <div className="single-p__technologies__wrap">
                                        <ul className='single-p__technologies__list' data-aos="fade-up" data-aos-duration="800">
                                            {crntProject.technologies.map((item, index) => (
                                                <li
                                                    key={index}>
                                                    {item.icon}
                                                    <span>{item.iconText}</span>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                </div>
                            </section>

                            <section className='single-p__description bg-dark2'>
                                <SectionHeading title="description" />
                                <div className="single-p__description-wrap">
                                    <div className="container">
                                        <ul className="single-p__description-details list">
                                            {crntProject.description.map((item, index) => (
                                                 <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>

                                <div className="single-p__description-img">
                                    <img
                                        src={`${crntProject.descriptionImg}`}
                                        alt="hero-img"
                                        data-aos="fade-left"
                                        data-aos-delay="600"
                                        data-aos-duration="1000"
                                    />
                                </div>
                            </section>

                            <div className='bgAttachment-min' style={{ backgroundImage: `url(${crntProject.layoutImg})` }} />

                            <section className='single-p__features'>
                                <SectionHeading title="features" />
                                <div className="single-p__features-img">
                                    <img
                                        src={`${crntProject.featuresImg}`}
                                        alt="hero-img"
                                        data-aos="fade-right"
                                        data-aos-delay="600"
                                        data-aos-duration="1000"
                                    />
                                </div>
                                <div className="single-p__features-wrap">
                                    <div className="container">
                                        <ul className="single-p__features-details list list-right">
                                            {crntProject.features.map((item, index) => (
                                                <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </section>

                            <section className='single-p__results'>
                                <SectionHeading title="results" />

                                <div className="single-p__results-wrap">
                                    <div className="container">
                                        <ul className="single-p__results-details list list-fullwith">
                                            {crntProject.results.map((item, index) => (
                                                <li key={index}>{item}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                            </section>

                            <section className='single-p__conclusion text-center bg-dark2'>
                                <SectionHeading title="conclusion" />
                                <div className="container">
                                    <p>{crntProject.conclusion}</p>
                                </div>
                            </section>
                        </div>) : (
                        <Page404 />
                    )}
                </PageWrapper>
            )}
        </>
    )
}

export default SingleProject