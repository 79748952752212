import React, { useEffect, useRef } from 'react'
import SectionHeading from '../SectionHeading/SectionHeading'
import "./Skills.scss"

const Skills = ({ data }) => {
    const { skills } = data;

    const cardsContainerRef = useRef(null);

    const handleMouseMove = (e) => {
      const cards = cardsContainerRef.current.getElementsByClassName("card");
  
      for (const card of cards) {
        const rect = card.getBoundingClientRect();
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
  
        card.style.setProperty("--mouse-x", `${x}px`);
      
        card.style.setProperty("--mouse-y", `${y}px`);       
      }
    };
  
    useEffect(() => {
      const cardsContainerElement = cardsContainerRef.current;
  
      cardsContainerElement.addEventListener('mousemove', handleMouseMove);
  
      return () => {
        cardsContainerElement.removeEventListener('mousemove', handleMouseMove);
      };
    }, []);

    return (
        <section className='skills section-pd' id='skills'>
             <div className="height-100"></div>
            <SectionHeading title="My Skills" />
            <div className="container">          
                <div className="skills__wrap">              
                    <ul className='skills__list' ref={cardsContainerRef}>
                        {skills.map((item, index) => (
                            <li
                                key={index}                          
                                data-aos={item.effect}
                                data-aos-duration={item.duration}
                                data-aos-delay={item.delay}
                                className="card">
                                {item.icon}
                                <span>{item.iconText}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </section>
    )
}

export default Skills