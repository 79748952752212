import Home from "../pages/Home";
import SingleProject from "../components/common/SingleProject/SingleProject";

const routes = [
  {
    index: true,
    element: <Home />,
    state: "home"
  },{
    path: "/portfolio/:projectName",
    element: <SingleProject />,
    state: "portfolio"
  },

];

export default routes;