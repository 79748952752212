import {
  FaHtml5,
  FaCss3Alt,
  FaReact,
  FaNodeJs,
  FaGitAlt,
  FaWordpress,
  FaJira,
  FaCookieBite,
} from "react-icons/fa";
import {
  SiMui,
  SiJavascript,
  SiJquery,
  SiRedux,
  SiExpress,
  SiPhp,
  SiMongodb,
  SiGulp,
  SiPostman,
  SiAdobephotoshop,
  SiAdobeillustrator,
  SiAdobexd,
  SiFigma,
  SiJsonwebtokens,
  SiDatabricks,
  SiSwiper,
  SiTailwindcss,
  SiSocketdotio,
} from "react-icons/si";

import { DiSass, DiMysql } from "react-icons/di";
import { GiLightningHelix } from "react-icons/gi";
import { CgMonday } from "react-icons/cg";
import { BsCalendar2DateFill } from "react-icons/bs";
import { MdEmail } from "react-icons/md";

import movierk_img from "./assets/images/movierk/movierk.webp";
import rkshop_img from "./assets/images/rkshop/rkshop.webp";
import chatApp_img from "./assets/images/chatApp/chatapp.webp";
import authrk_img from "./assets/images/authorizationrk/authorizationrk.webp";

import movieRK_headerImg from "./assets/images/movierk/movieRK-header.webp";
import movieRK_layoutImg from "./assets/images/movierk/movieRK-layout.webp";
import movieRK_descriptionImg from "./assets/images/movierk/movieRK-descripton.webp";
import movieRK_featuresImg from "./assets/images/movierk/movieRK-features.webp";

import barbershop from "./assets/images/barbershop/barbershop.webp";
import barbershop_headerImg from "./assets/images/barbershop/barbershop-header.webp";
import barbershop_layoutImg from "./assets/images/barbershop/barbershop-layout.webp";
import barbershop_descriptionImg from "./assets/images/barbershop/barbershop-description.webp";
import barbershop_featuresImg from "./assets/images/barbershop/barbershop-features.webp";

import rkshop_headerImg from "./assets/images/rkshop/rkshop-header.webp";
import rkshop_layoutImg from "./assets/images/rkshop/rkshop-layout.webp";
import rkshop_descriptionImg from "./assets/images/rkshop/rkshop-description.webp";
import rkshop_featuresImg from "./assets/images/rkshop/rkshop-features.webp";

import authorizationrk_headerImg from "./assets/images/authorizationrk/authorizationrk-header.webp";
import authorizationrk_layoutImg from "./assets/images/authorizationrk/authorizationrk-layout.webp";
import authorizationrk_descriptionImg from "./assets/images/authorizationrk/authorizationrk-description.webp";
import authorizationrk_featuresImg from "./assets/images/authorizationrk/authorizationrk-features.webp";

import chatapp__headerImg from "./assets/images/chatApp/chatapp-header.webp";
import chatapp_layoutImg from "./assets/images/chatApp/chatapp-layout.webp";

import chatapp_descriptionImg from "./assets/images/chatApp/chatapp-description.webp";
import chatapp_featuresImg from "./assets/images/chatApp/chatapp-features.webp";

export const skillsData = {
  skills: [
    {
      iconText: "html5",
      icon: <FaHtml5 className="html5" />,
      effect: "fade-right",
      duration: "500",
      delay: "200",
    },
    {
      iconText: "css3",
      icon: <FaCss3Alt className="css3" />,
      effect: "fade-down",
      duration: "500",
      delay: "200",
    },
    {
      iconText: "JavaScript",
      icon: <SiJavascript className="JavaScript" />,
      effect: "fade-down",
      duration: "500",
      delay: "200",
    },
    {
      iconText: "Jquery",
      icon: <SiJquery className="Jquery" />,
      effect: "fade-down",
      duration: "500",
      delay: "200",
    },
    {
      iconText: "ReactJs",
      icon: <FaReact className="ReactJs" />,
      effect: "fade-down",
      duration: "500",
      delay: "200",
    },
    {
      iconText: "redux",
      icon: <SiRedux className="redux" />,
      effect: "fade-left",
      duration: "500",
      delay: "200",
    },
    {
      iconText: "MUI",
      icon: <SiMui className="MUI" />,
      effect: "fade-right",
      duration: "500",
      delay: "200",
    },
    {
      iconText: "scss",
      icon: <DiSass className="scss" />,
    },
    {
      iconText: "TailwindCSS",
      icon: <SiTailwindcss className="tailwindcss" />,
    },
    {
      iconText: "NodeJS",
      icon: <FaNodeJs className="NodeJS" />,
    },
    {
      iconText: "ExpressJS",
      icon: <SiExpress className="ExpressJS" />,
    },
    {
      iconText: "PHP",
      icon: <SiPhp className="PHP" />,
    },
    {
      iconText: "MySQL",
      icon: <DiMysql className="MySQL" />,
      effect: "fade-left",
      duration: "500",
      delay: "200",
    },
    {
      iconText: "MongoDB",
      icon: <SiMongodb className="MongoDB" />,
      effect: "fade-right",
      duration: "500",
      delay: "200",
    },
    {
      iconText: "Git",
      icon: <FaGitAlt className="Git" />,
    },
    {
      iconText: "Gulp",
      icon: <SiGulp className="Gulp" />,
    },
    {
      iconText: "Postman",
      icon: <SiPostman className="Postman" />,
    },
    {
      iconText: "Powerlink",
      icon: <GiLightningHelix className="Powerlink" />,
      effect: "fade-left",
      duration: "500",
      delay: "200",
    },
    {
      iconText: "Monday",
      icon: <CgMonday className="Monday" />,
      effect: "fade-right",
      duration: "500",
      delay: "200",
    },
    {
      iconText: "Jira",
      icon: <FaJira className="Jira" />,
      effect: "fade-up",
      duration: "500",
      delay: "200",
    },
    {
      iconText: "Photoshop",
      icon: <SiAdobephotoshop className="Photoshop" />,
      effect: "fade-up",
      duration: "500",
      delay: "200",
    },
    {
      iconText: "Illustrator",
      icon: <SiAdobeillustrator className="Illustrator" />,
      effect: "fade-up",
      duration: "500",
      delay: "200",
    },
    {
      iconText: "XD",
      icon: <SiAdobexd className="XD" />,
      effect: "fade-up",
      duration: "500",
      delay: "200",
    },
    {
      iconText: "Figma",
      icon: <SiFigma className="Figma" />,
      effect: "fade-left",
      duration: "500",
      delay: "200",
    },
  ],
};

export const resumeData = {
  education: [
    {
      title: "Full Stack developer in WEB technologies and applications",
      link: "https://www.johnbryce.co.il/",
      hours: "470 total hours",
      duration: "2022-2023",
      subTitle: "@ John Bryce College",
      text: [
        "HTML5, CSS3/Sass, Bootstrap, JavaScript (ES6) / jQuery",
        "React, Angular, Type Script & OOP",
        "Node.js, PHP, MongoDB, MySQL, Docker, Web Services, GIT",
      ],
    },
    {
      title: "A Degree in Practical Engineering. Graduation with honors",
      link: "https://braude.ort.org.il/",
      duration: "2017 - 2020",
      subTitle: "@ ORT Braude",
      text: [
        "Data Structure, OOP, Operating Systems, Algorithms",
        "Final project: LOCK - is a server-client application written in C# language designed to remotely control the activities of a child on a computer.A protocol for routing and data transfer was created in the project.",
      ],
    },
    {
      title: "Practical JavaScript - certification",
      link: "https://www.udemy.com/course/javascript_practice/",
      hours: "23.5 total hours",
      duration: "2023",
      subTitle: "@ Udemy",
      text: ["Advanced Level - JavaScript (ES6)"],
    },
    {
      title: "WEB developer 2022 - certification",
      link: "https://www.udemy.com/course/webdeveloper/",
      hours: "33.5 total hours",
      duration: "2022 - 2023",
      subTitle: "@ Udemy",
      text: [
        "Frontend skills such HTML5, CSS3/Sass, Bootstrap, Pixel Perfect",
        "Git, GitHub, Gulp, etc",
      ],
    },
    {
      title: "JavaScript + React - certification",
      link: "https://www.udemy.com/course/javascript_full/",
      hours: "66.5 total hours",
      duration: "2021 - 2022",
      subTitle: "@ Udemy",
      text: [
        "Frontend skills such HTML5, CSS3, JavaScript (ES6)",
        "React, Type Script & OOP, Redux, etc",
      ],
    },
  ],
  experience: [
    {
      title: "Junior Full-stack web developer - WordPress developer",
      link: "https://ivy.co.il/",
      duration: "2022 - 2023",
      subTitle: "@ IVY",
      text: [
        "Writing modern, high-performance, and reliable code for diverse client projects.",
        "Working with a wide range of programming languages and libraries , including JavaScript, jQuery, PHP, and content management systems (CRM) like WordPress, Powerlink.",
        "Designing and developing marketing websites, e-commerce platforms, and digital platforms for clients, taking into account their needs and target audience.",
        "Creating and configuring API services to facilitate data exchange and management between different systems, ensuring efficient interaction and integration.",
        "Maintaining regular client communication, including meetings and discussions of technical matters, providing consultations and solutions, and supporting the implementation and optimization of developed solutions.",
        "Efficient project tracking and meeting deadlines by utilizing project and task management platforms such as Monday and Jira.",
      ],
    },
  ],
};

export const portfolioData = {
  promo: [
    {
      title: "barbershop",
      span: "",
      img: barbershop,
      gitHubLink: "https://github.com/roman-kalistratov/barbershopRK",
      projectLink: "https://barbershoprk.onrender.com/",
      effect: "fade-up",
      duration: "500",
      delay: "200",
      technologies: [
        {
          iconText: "html5",
          icon: <FaHtml5 className="html5" />,
        },
        {
          iconText: "reactJs",
          icon: <FaReact className="ReactJS" />,
        },
        {
          iconText: "redux",
          icon: <SiRedux className="redux" />,
        },
        {
          iconText: "MUI",
          icon: <SiMui className="MUI" />,
        },
      ],
      projectName: "barbershop",
    },
    {
      title: "movie",
      span: "rk",
      img: movierk_img,
      gitHubLink: "https://github.com/roman-kalistratov/movieRK",
      projectLink: "https://movierk.vercel.app/",
      effect: "fade-up",
      duration: "500",
      delay: "300",
      projectName: "movierk",
    },
    {
      title: "shop",
      span: "rk",
      img: rkshop_img,
      gitHubLink: "https://github.com/roman-kalistratov/RKShop",
      projectLink: "https://rkshop.vercel.app/",
      effect: "fade-up",
      duration: "500",
      delay: "400",
      projectName: "rkshop",
    },
    {
      title: "Chat app",
      span: "",
      img: chatApp_img,
      gitHubLink:
        "https://github.com/roman-kalistratov/mern-chat-app/tree/main",
      projectLink: "https://mern-chat-app-s9eg.onrender.com/",
      effect: "fade-up",
      duration: "500",
      delay: "500",
      projectName: "chatApp",
    },
    {
      title: "Auth system",
      span: "",
      img: authrk_img,
      gitHubLink: "https://github.com/roman-kalistratov/authorization-system",
      projectLink: "https://authorizationrk.vercel.app/",
      effect: "fade-up",
      duration: "500",
      delay: "500",
      projectName: "authorizationrk",
    },
  ],
};

export const projectsData = [
  {
    projectName: "movierk",
    title: "Movierk",
    subtitle: "Interactive Online Movie Catalog",
    demoLink: "https://movierk.vercel.app/",
    gitHubLink: "https://github.com/roman-kalistratov/movieRK",
    concept:
      "To create an online platform that allows users to search and explore information about various movies. The main goal of the project is to help users find their desired movies, obtain detailed information about them, and share their recommendations with other film enthusiasts. The platform will feature functionalities such as searching for movies by title, filtering by ratings, as well as displaying detailed information about each movie, including description, cast, trailer, and user reviews.",
    technologies: [
      {
        iconText: "html5",
        icon: <FaHtml5 className="html5" />,
      },
      {
        iconText: "reactJs",
        icon: <FaReact className="ReactJS" />,
      },
      {
        iconText: "redux",
        icon: <SiRedux className="redux" />,
      },
      {
        iconText: "MUI",
        icon: <SiMui className="MUI" />,
      },
      {
        iconText: "nodeJs",
        icon: <FaNodeJs className="NodeJS" />,
      },
      {
        iconText: "expressJS",
        icon: <SiExpress className="ExpressJS" />,
      },
      {
        iconText: "mongoDB",
        icon: <SiMongodb className="MongoDB" />,
      },
      {
        iconText: "JWT",
        icon: <SiJsonwebtokens className="jwt" />,
      },
      {
        iconText: "formik",
        icon: <SiDatabricks className="formik" />,
      },
      {
        iconText: "swiper",
        icon: <SiSwiper className="swiper" />,
      },
      {
        iconText: "cookie-parser",
        icon: <FaCookieBite className="cookie" />,
      },
    ],
    description: [
      "Movierk allows users to leave reviews for movies and share their recommendations with other users.",
      "The website utilizes algorithms to provide movie recommendations based on user preferences and ratings received from other users.",
      "Movierk has an intuitive and appealing design that ensures user-friendly usage and navigation.",
      "The project utilizes React.js and <a href='https://mui.com/' target='_blank' rel='noopener noreferrer'>Material UI</a> for creating an interactive user interface.",
      "Node.js, Express, and MongoDB are used for building the server-side.",
      "<a href='https://api.themoviedb.org/' target='_blank' rel='noopener noreferrer'>REST API</a> is employed for interacting with external services to retrieve movie data.",
    ],
    features: [
      "Utilizes the <a href='https://api.themoviedb.org/' target='_blank' rel='noopener noreferrer'>free REST API</a> for retrieving and processing media data.",
      "Utilizes JWT technology to provide authentication and authorization capabilities for users.",
      "Registration and login form validation is performed using <a href='https://formik.org/' target='_blank' rel='noopener noreferrer'>Formik</a> and Yup on the client-side, and <a href='https://express-validator.github.io/docs' target='_blank' rel='noopener noreferrer'>Express-validator</a> on the server-side.",
      "Passwords are hashed on the server-side using the crypto module and converted into hexadecimal representation.",
      "Allows users who have been authenticated to change their passwords.",
      "Uses <a href='https://www.mongodb.com/' target='_blank' rel='noopener noreferrer'>MongoDB</a> with the Mongoose library as the database.",
      "Enables users to leave their reviews for media and add them to their favorites",
      "Features an intuitive and appealing design, ensuring user-friendly usage and navigation.",
      "Redux Toolkit is used for client-side state management.",
    ],
    results: [
      "A fully functional online platform called Movierk has been created as a result of the project, which successfully provides users with the ability to search, explore, and share information about movies and TV shows.",
      "Working on the Movierk project allowed me to deepen my knowledge in developing web applications using React.js and integrating with external APIs.",
      "I gained experience in creating user interfaces, developing algorithms, and working with databases to store user data.",
      "Additionally, I acquired expertise in building user identification mechanisms for registration and authentication.",
    ],
    conclusion:
      "The platform offers a wide selection of movies and TV series across genres. Discover your favorite films and explore cinematic masterpieces. The user-friendly interface enables easy browsing, finding movies of interest, watching trailers, and accessing additional information. Enjoy an immersive movie experience on this captivating platform.",
    layoutImg: movieRK_layoutImg,
    headerImg: movieRK_headerImg,
    descriptionImg: movieRK_descriptionImg,
    featuresImg: movieRK_featuresImg,
  },
  {
    projectName: "barbershop",
    title: "Barbershop",
    subtitle: "Queue booking system",
    demoLink: "https://barbershoprk.onrender.com/",
    gitHubLink: "https://github.com/roman-kalistratov/barbershopRK",
    concept:
      "The main goal of the project is to provide users with a convenient and reliable way to book haircuts online. It includes offering a selection of services, the ability to view detailed information about each service, book a queue, and choose a convenient time for their visit.",
    technologies: [
      {
        iconText: "html5",
        icon: <FaHtml5 className="html5" />,
      },
      {
        iconText: "reactJs",
        icon: <FaReact className="ReactJS" />,
      },
      {
        iconText: "redux",
        icon: <SiRedux className="redux" />,
      },
      {
        iconText: "datepicker",
        icon: <BsCalendar2DateFill />,
      },
      {
        iconText: "nodeJs",
        icon: <FaNodeJs className="NodeJS" />,
      },
      {
        iconText: "scss",
        icon: <DiSass className="scss" />,
      },
      {
        iconText: "expressJS",
        icon: <SiExpress className="ExpressJS" />,
      },
      {
        iconText: "mongoDB",
        icon: <SiMongodb className="MongoDB" />,
      },
      {
        iconText: "JWT",
        icon: <SiJsonwebtokens className="jwt" />,
      },
    ],
    description: [
      "The project is a landing page with a user-friendly interface for browsing available services, including descriptions and prices.",
      "Users can select their desired service, barber, and specify their preferred time and date.",
      "Features an intuitively designed and stylish layout, ensuring convenience in usage and navigation.",
      "Utilizes React.js and SCSS to create an interactive user interface.",
      "Node.js, Express, and MongoDB are used for server interaction and order processing.",
    ],
    features: [
      "The project utilizes JWT technology to provide authentication and authorization capabilities for users and barbers.",
      "Passwords are hashed on the server-side using the bcrypt module and transformed into hexadecimal representation.",
      "The project uses MongoDB with the Mongoose library as the database.",
      "The <a href='https://michalsnik.github.io/aos/' target='_blank' rel='noopener noreferrer'>AOS library</a> was employed for animation purposes.",
      "The <a href='https://reactdatepicker.com/' target='_blank' rel='noopener noreferrer'> react-datepicker</a> and <a href='https://date-fns.org/' target='_blank' rel='noopener noreferrer'> date-fns</a> libraries were utilized for handling calendars and dates.",
      "Redux is used for client-side state management.",
    ],
    results: [
      "Working on the project enabled the expansion of knowledge and experience in developing web applications using React.js, Node.js, calendar integration, and date processing.",
    ],
    conclusion:
      "The website is an online platform for booking barber services. Key features include user authentication, selection of barbers, services, dates, and times, removal of booked sessions from barbers' schedules, and managing appointments in the user's account. The site boasts a sleek design, ensuring easy navigation and a user-friendly experience.",
    layoutImg: barbershop_layoutImg,
    headerImg: barbershop_headerImg,
    descriptionImg: barbershop_descriptionImg,
    featuresImg: barbershop_featuresImg,
  },
  {
    projectName: "rkshop",
    title: "RKShop",
    subtitle: "Online Marketplace",
    demoLink: "https://rkshop.vercel.app/",
    gitHubLink: "https://github.com/roman-kalistratov/RKShop",
    concept:
      "To create an online platform that provides the opportunity to purchase various products. The main goal of the project is to offer users a convenient way to search, sort, and buy products online.",
    technologies: [
      {
        iconText: "html5",
        icon: <FaHtml5 className="html5" />,
      },
      {
        iconText: "reactJs",
        icon: <FaReact className="ReactJS" />,
      },
      {
        iconText: "redux",
        icon: <SiRedux className="redux" />,
      },
      {
        iconText: "scss",
        icon: <DiSass className="scss" />,
      },
      {
        iconText: "nodeJs",
        icon: <FaNodeJs className="NodeJS" />,
      },
      {
        iconText: "expressJS",
        icon: <SiExpress className="ExpressJS" />,
      },
      {
        iconText: "mongoDB",
        icon: <SiMongodb className="MongoDB" />,
      },
      {
        iconText: "JWT",
        icon: <SiJsonwebtokens className="jwt" />,
      },
      {
        iconText: "sendGrid",
        icon: <MdEmail />,
      },
      {
        iconText: "swiper",
        icon: <SiSwiper className="swiper" />,
      },
      {
        iconText: "cookie-parser",
        icon: <FaCookieBite className="cookie" />,
      },
    ],
    description: [
      "The project provides a user-friendly interface for browsing products, including images, descriptions, and prices.",
      "Users can add selected items to their cart, view the contents of the cart, and proceed to purchase the selected products.",
      "Users can add selected items to their favorites and manage them.",
      "The project utilizes React.js and SCSS for creating an interactive user interface.",
      "Node.js, Express, and MongoDB are used for server-side interaction and order processing.",
      "The project incorporates the functionality of applying discount coupons during the checkout process to avail discounts.",
    ],
    features: [
      "Utilizes the <a href='https://dummyjson.com' target='_blank' rel='noopener noreferrer'>free REST API</a> for retrieving and processing products data.",
      "Utilizes JWT technology to provide authentication, authorization and reset password capabilities for users.",
      "Password reset is accomplished by sending a JWT token to the user's email address.",
      "Form validation is performed using <a href='https://formik.org/' target='_blank' rel='noopener noreferrer'>Formik</a> and Yup on the client-side, and Express-validator on the server-side.",
      "Passwords are hashed on the server-side using the bcrypt module and transformed into hexadecimal representation.",
      "Uses <a href='https://www.mongodb.com/' target='_blank' rel='noopener noreferrer'>MongoDB</a> with the Mongoose library as the database.",
      "Users have the ability to add selected items to their shopping cart and manage its contents. The data is processed on the client-side using Redux Toolkit and stored in the server-side database.",
    ],
    results: [
      "As a result of the work, a functional online platform has been created, which successfully provides users with the ability to purchase various products and place orders.",
      "Working on the RKShop project has significantly expanded knowledge and experience in developing ecommerce web applications, particularly in the areas of saving and processing user data in the shopping cart, order processing, and interaction with the database.",
    ],
    conclusion:
      "The E-commerce web project provides an optimal website structure and a convenient order processing system. The project demonstrates the ability to work with user authentication and authorization, data retrieval and filtering for products, and the capability to manage purchases and favorite items.",
    layoutImg: rkshop_layoutImg,
    headerImg: rkshop_headerImg,
    descriptionImg: rkshop_descriptionImg,
    featuresImg: rkshop_featuresImg,
  },
  {
    projectName: "authorizationrk",
    title: "Auth System",
    subtitle: "Secure user authentication",
    demoLink: "https://authorizationrk.vercel.app/",
    gitHubLink: "https://github.com/roman-kalistratov/authorization-system",
    concept:
      "The main goal of this project is to provide a secure and user-friendly authentication system to ensure that users can access any platform with confidence and protect their personal information.The emphasis will be on creating a seamless and secure authentication process.",
    technologies: [
      {
        iconText: "html5",
        icon: <FaHtml5 className="html5" />,
      },
      {
        iconText: "reactJs",
        icon: <FaReact className="ReactJS" />,
      },
      {
        iconText: "redux",
        icon: <SiRedux className="redux" />,
      },
      {
        iconText: "MUI",
        icon: <SiMui className="MUI" />,
      },
      {
        iconText: "nodeJs",
        icon: <FaNodeJs className="NodeJS" />,
      },
      {
        iconText: "expressJS",
        icon: <SiExpress className="ExpressJS" />,
      },
      {
        iconText: "mongoDB",
        icon: <SiMongodb className="MongoDB" />,
      },
      {
        iconText: "JWT",
        icon: <SiJsonwebtokens className="jwt" />,
      },
      {
        iconText: "formik",
        icon: <SiDatabricks className="formik" />,
      },
      {
        iconText: "cookie-parser",
        icon: <FaCookieBite className="cookie" />,
      },
    ],
    description: [
      "Allows users to register and login.",
      "Allows users to recover and reset their password.",
      "Allows users to remain logged in when logged in.",
      "The ability to login using a standard form or using a code.",
      "Secure transition to the protected page for registered users.",
      "The project utilizes React.js and <a href='https://mui.com/' target='_blank' rel='noopener noreferrer'>Material UI</a> for creating an interactive user interface.",
      "Node.js, Express, and MongoDB are used for building the server-side.",
      "<a href='https://sendgrid.com/' target='_blank' rel='noopener noreferrer'>Sendgrid</a> is used to send messages by email.",
    ],
    features: [
      "Utilizes JWT technology to provide authentication and authorization capabilities for users.",
      "Forms validation is performed using <a href='https://formik.org/' target='_blank' rel='noopener noreferrer'>Formik</a> and Yup on the client-side.",
      "Passwords are hashed on the server-side using the bcryptjs module and converted into hexadecimal representation.",
      "Allows users who have been authenticated to recover their password.",
      "Uses <a href='https://www.mongodb.com/' target='_blank' rel='noopener noreferrer'>MongoDB</a> with the Mongoose library as the database.",
      "Features an intuitive and appealing design, ensuring user-friendly usage and navigation.",
      "Redux Toolkit is used for client-side state management.",
    ],
    results: [
      "Consequently, a fully operational online platform has been established, enabling secure registration and login processes.",
      "Deepening my understanding of the authentication and authorization system grants me more opportunities to flexibly control my content on the website in future projects.",
    ],
    conclusion:
      "The work on the project allowed me to understand how user authentication operates behind the scenes. I delved into comprehending how data persists in the system with each subsequent login to the website and how it is possible to restrict content for users.",
    layoutImg: authorizationrk_layoutImg,
    headerImg: authorizationrk_headerImg,
    descriptionImg: authorizationrk_descriptionImg,
    featuresImg: authorizationrk_featuresImg,
  },
  {
    projectName: "chatApp",
    title: "Chat App",
    subtitle: "Real-time chat",
    demoLink: "https://mern-chat-app-s9eg.onrender.com/",
    gitHubLink: "https://github.com/roman-kalistratov/mern-chat-app/tree/main",
    concept:
      "The aim is to develop a real-time chat application using the MERN (MongoDB, Express.js, React.js, Node.js) stack along with Socket.io for seamless and instant communication between users.",
    technologies: [
      {
        iconText: "html5",
        icon: <FaHtml5 className="html5" />,
      },
      {
        iconText: "reactJs",
        icon: <FaReact className="ReactJS" />,
      },
      {
        iconText: "zustand",
        icon: <SiRedux className="redux" />,
      },
      {
        iconText: "tailwindcss",
        icon: <SiTailwindcss className="tailwindcss" />,
      },
      {
        iconText: "nodeJs",
        icon: <FaNodeJs className="NodeJS" />,
      },
      {
        iconText: "expressJS",
        icon: <SiExpress className="ExpressJS" />,
      },
      {
        iconText: "mongoDB",
        icon: <SiMongodb className="MongoDB" />,
      },
      {
        iconText: "JWT",
        icon: <SiJsonwebtokens className="jwt" />,
      },
      {
        iconText: "formik",
        icon: <SiDatabricks className="formik" />,
      },
      {
        iconText: "socket.io",
        icon: <SiSocketdotio className="swiper" />,
      },
    ],
    description: [
      "Real-Time Messaging: Enable users to send and receive messages instantly without the need for refreshing the page.",
      "Message History: Store chat history to allow users to view past conversations.",
      "User Online Status: Display real-time status indicators to show when users are online or offline.",
      "User Profiles: Allow users to customize their profiles.",
      "Node.js, Express and MongoDB are used for building the server-side.",
      "React.js and Zustand are used for building the client-side.",
      "Real-Time Communication: Socket.io for enabling real-time, bidirectional communication between clients and server.",
    ],
    features: [
      "Utilizes JWT technology to provide authentication and authorization capabilities for users.",
      "Socket.io to handle real-time messaging between clients and server.",
      "Uses <a href='https://www.mongodb.com/' target='_blank' rel='noopener noreferrer'>MongoDB</a> with the Mongoose library as the database.",
      "Allows users to add to favorites, block, and delete contacts.",
      "Features an intuitive and appealing design, ensuring user-friendly usage and navigation.",
      "Also includes the ability to change themes and notification sounds when receiving a new message.",
      "Zustand is used for client-side state management.",
    ],
    results: [
      "As a result of the project, a fully functional online platform called Chat App has been created, providing users with seamless real-time communication.",
      "Working on the project allowed me to deepen my knowledge in developing web applications using Socket.io",
    ],
    conclusion:
      "Creating a real-time chat app with MERN stack and Socket.io improved my full-stack skills and deepened my understanding of real-time data transmission.",
    layoutImg: chatapp_layoutImg,
    headerImg: chatapp__headerImg,
    descriptionImg: chatapp_descriptionImg,
    featuresImg: chatapp_featuresImg,
  },
];
