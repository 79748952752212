import React, { useEffect } from 'react'
import Particles from '../Particles'
import SocialLinks from '../SocialLinks/SocialLinks';
import { Link as ScrollLink } from 'react-scroll';
import hero_img from '../../../assets/images/hero-img.webp'
import './Hero.scss'

const Hero = () => {
    useEffect(() => {
        const handleScroll = () => {
            const scrollValue = window.scrollY;
            const heroElements = document.querySelector('.hero .hero__img');
            if (heroElements) {
                heroElements.style.right = `${scrollValue * -0.1}px`;
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);


    return (
        <section className='hero' id="hero">
            <Particles />
            <div className="hero__wrapper">
                <div className="container">
                    <div className="hero__text">
                        <h3> Hi, I'm </h3>
                        <h1> Kalistratov <br /> Roman </h1>
                        <h2> Full-Stack Web Developer </h2>
                        <div className="about__btn">
                            <ScrollLink className="btn" to="contact">
                                Hire Me
                            </ScrollLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className="hero__img">
                <img
                    src={`${hero_img}`}
                    alt="hero-img"
                    data-aos="fade-left"
                    data-aos-delay="1000"
                    data-aos-duration="1000"
                />
                <div
                    className="hero__socialLinks"
                    data-aos="fade-right"
                    data-aos-delay="1000"
                    data-aos-duration="1000"
                >
                    <SocialLinks />
                </div>
            </div>
        </section>
    )
}

export default Hero