import React, { useCallback, useMemo } from 'react'
import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim";

const ParticlesComponent = () => {
    const options = useMemo(() => {
        return {
            background: {
                color: {
                    value: "inherit",
                },
            },
            fullScreen: {
                enable: false,
                zIndex: -1,
            },
            style: {
                position: "absolute",
                zIndex: -1,
            },
            fpsLimit: 120,
            interactivity: {
                events: {
                    onHover: {
                        enable: true,
                        mode: "attract",
                        parallax: { enable: true, force: 160, smooth: 10 }
                    },
                    resize: true,
                },
                modes: {
                    push: { quantity: 0 },
                    attract: { distance: 100, duration: .4, factor: 1 }
                  }
            },
            particles: {               
                color: {
                    value: "#ffffff",
                },
                move: {
                    enable: true,
                    speed: .2,

                },
                number: {
                    density: {
                        enable: true,
                        area: 800,
                    },
                    value: 200,
                },
                opacity: {
                    animation: {
                        enable: true,
                        speed: 1,
                        sync: true,
                    },
                    value: {
                        min: 0,
                        max: 0.5
                    }
                },
                size: {
                    value: { min: 1, max: 2 },
                },
                shape: {
                    type: "circle"
                } 
            },
            detectRetina: true,
        }
    }, [])

    const particlesInit = useCallback(engine => {
        loadSlim(engine);
    }, []);

    return (
        <Particles
            id="tsparticles"
            init={particlesInit}
            options={options}
        />)
}

export default ParticlesComponent 