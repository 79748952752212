import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setNavSidebar } from '../../../redux/features/navSidebarSlice';
import { setHamburger } from '../../../redux/features/hamburgerSlice';

import './Hamburger.scss';
const Hamburger = () => {
    const { navSidebar } = useSelector((state) => state.navSidebar);
    const { isHamburger } = useSelector((state) => state.isHamburger);

    const dispatch = useDispatch()

    const handleClick = () => {
        dispatch(setNavSidebar(!navSidebar));
        dispatch(setHamburger(!isHamburger));
    }

    // disable scrolling when the menu is open
    useEffect(() => {
        navSidebar ? document.body.classList.add('sidebar-opened') : document.body.classList.remove('sidebar-opened')
    }, [navSidebar]);

    return (
        <div className="hamburger ">
            <div className={`hamburger__wrapper ${isHamburger ? "active" : undefined}`} onClick={handleClick}>
                <div className="pix"></div>
                <div className="pix"></div>
                <div className="pix"></div>
                <div className="pix"></div>
                <div className="pix"></div>
                <div className="pix"></div>
                <div className="pix"></div>
                <div className="pix"></div>
                <div className="pix"></div>
            </div>
        </div>
    )
}

export default Hamburger