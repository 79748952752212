import React from 'react'
import SectionHeading from '../SectionHeading/SectionHeading'
import icon1 from "../../../assets/images/resume-icon1.png"
import icon2 from "../../../assets/images/resume-icon2.png"
import SingleResume from './SingleResume'
import './Resume.scss'

const Resume = ({ data }) => {
    const { education, experience } = data;

    return (
        <section className='resume section-pd'>
            <div className="height-100"></div>
            <SectionHeading title="Resume" />
            <div className="container">
                <div className="block-wrap">

                    <div className="block-left">
                        <div className="resume__heading">
                            <img src={icon1} alt="resume-icon1" />
                            <h2 className="resume__heading-title">Education</h2>
                        </div>

                        <div className="resume__timeline-wrap" >
                            {education.map((education, index) => (
                                <SingleResume element={education} key={index} />
                            ))}
                        </div>
                    </div>

                    <div className="block-right">
                        <div className="resume__heading">
                            <img src={icon2} alt="resume-icon2" />
                            <h2 className="resume__heading-title">Experience</h2>
                        </div>
                        <div className="resume__timeline-wrap" >
                            {experience.map((education, index) => (
                                <SingleResume element={education} key={index} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Resume