import React from 'react'
import { animateScroll as scroll } from 'react-scroll';
import logo from '../../../assets/images/Logo-Yellow.webp'
import { Link } from 'react-router-dom';
import './Logo.scss'

const Logo = () => {
    const options = {
        duration: 0,
        smooth: true,
    };

    const scrollToTop = () => {
        scroll.scrollToTop(options);
    };

    return (
        <Link onClick={scrollToTop} to="/">
            <div className="logo">
                <img src={logo} alt="logo" />
            </div>
        </Link>
    )
}

export default Logo