import { createSlice } from "@reduxjs/toolkit";

export const hamburger = createSlice({
  name: "Hamburger",
  initialState: {
    isHamburger: false
  },
  reducers: {
    setHamburger: (state, action) => {
      state.isHamburger = action.payload;
    }
  }
});

export const {
    setHamburger
} = hamburger.actions;

export default hamburger.reducer;