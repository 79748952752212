import React, { useEffect, useState } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAppState } from "../../../redux/features/appStateSlice";
import { setNavSidebar } from '../../../redux/features/navSidebarSlice';
import { setHamburger } from '../../../redux/features/hamburgerSlice';
import menuConfigs from '../../../configs/menu.configs';
import Logo from '../Logo/Logo';
import './Header.scss';

const Header = () => {
  const location = useLocation();
  const { appState } = useSelector((state) => state.appState);
  const [isScrolled, setIsScrolled] = useState(false);
  const { navSidebar } = useSelector((state) => state.navSidebar);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleScroll = () => { 
      if (window.scrollY >= 10) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [appState, dispatch]);

  const navigate = useNavigate();
  const handleClick = (state) => {
    dispatch(setAppState(state))
    navSidebar && dispatch(setNavSidebar(false))
    navSidebar && dispatch(setHamburger(false))

    // check url path if url path contains parameters 
    // then a function is triggered that takes you to the desired section of the main page
    if (location.pathname !== '/') {
      const sectionId = state;
      navigate('/');
      setTimeout(() => {
        const sectionElement = document.getElementById(sectionId);
        if (sectionElement) {
          sectionElement.scrollIntoView({ behavior: 'smooth' });
        }
      }, 100);
    }
  }

  return (
    <header className={`header ${isScrolled ? 'sticky-active' : ''} ${navSidebar ? 'header__active' : ''}`} id='home'>
      <div className="header__wrapper">
        <div className="container">
          <div className="header__in">
            <div className="header__left">
              <Logo />
            </div>
            <div className="header__right">
              <nav className='nav'>
                <ul className='nav__list'>
                  {menuConfigs.main.map((item, index) => (
                    <li key={item.state}>
                      <ScrollLink                                                       
                        to={item.state}
                        spy={true}
                        smooth={true}
                        offset={-80}
                        duration={0}
                        onClick={() => handleClick(item.state)}>{item.display}
                      </ScrollLink>
                    </li>
                  ))}
                </ul>
              </nav>
            </div>           
          </div>
        </div>
      </div>
    </header>
  )
}

export default Header;
