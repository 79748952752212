import React from 'react'
import "./Loader.scss"

const Loader = () => {
    return (
        <div className='loader'>
            <svg>
                <text x="50%" y="50%" dy=".35em" textAnchor="middle">
                    RK
                </text>
            </svg>
        </div>
    )
}

export default Loader