import { FaLinkedin, FaFacebookF, FaGithub } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

const main = [
  {
    display: "home",
    state: "hero"
  },
  {
    display: "about",
    state: "about"
  }, {
    display: "skills",
    state: "skills"
  },
  {
    display: "resume",
    state: "resume"
  },
  {
    display: "portfolio",
    state: "portfolio"
  },
  {
    display: "contact",
    state: "contact"
  }
];

const social = [
  {
    display: "LinkedIn",
    icon: <FaLinkedin />,
    path: "https://www.linkedin.com/in/roman-kalistratov/",
    state: "LinkedIn",
    active: true
  },
  {
    display: "Facebook",
    icon: <FaFacebookF />,
    path: "https://www.facebook.com/roman.kalistratov.9",
    state: "Facebook",
    active: false
  },
  {
    display: "GitHub",
    icon: <FaGithub />,
    path: "https://github.com/roman-kalistratov",
    state: "GitHub",
    active: false
  },
  {
    display: "Message",
    icon: <MdEmail />,
    path: "mailto:kalistratov.rk@gmail.com",
    state: "Message",
    active: false
  }
];

const menuConfigs = { main, social };

export default menuConfigs;