import React, { useEffect, useState } from "react"
import { Outlet } from "react-router-dom"
import Header from "../common/Header/Header"
import Hamburger from "../common/Hamburger/Hamburger"
import ScrollToTop from "../common/ScrollToTop/ScrollToTop"
import Loader from "../common/Loader/Loader"
import Footer from "../common/Footer/Footer"


const MainLayout = () => {
  const [isLoader, setIsLoader] = useState(false);

  useEffect(() => {   
    setTimeout(() => {
      setIsLoader(false);
    }, 2000);
  }, []);

  return (
    <>
      {isLoader ? <Loader /> : (
        <>
          <Hamburger />
          <Header />
          <div className="wrapper">
            <main className="main">
              <ScrollToTop />
              <Outlet />
            </main>
            <Footer/>
          </div>
        </>
      )}

    </>
  )
}

export default MainLayout