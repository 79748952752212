import React from 'react';
import { useDispatch } from 'react-redux';
import { setAppState } from "../../../redux/features/appStateSlice";
import { Link } from "react-router-dom";
import "./Page404.scss";

const Page404 = () => {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(setAppState("hero"))
  }

  return (
    <div className="page404">
      <div className="page404__wrap">
        <h3>4<span>0</span>4</h3>
        <h4>Page Not Found</h4>
        <p>oops, the page you are looking for can't be found!</p>
        <Link to='/' className="btn" onClick={() => handleClick()}>Go to Home</Link>
      </div>
    </div>
  )
}

export default Page404
