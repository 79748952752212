import React from 'react'
import './SectionHeading.scss'

const SectionHeading = ({ title }) => {
    return (
        <div className='container'>
            <div className="overflow">
                <div className="sectionHeading" data-aos="fade-up" data-aos-duration="800">
                    <h4 className='sectionHeading__title'>{title}</h4>
                    <h2 className='sectionHeading__subtitle'>{title}</h2>
                </div>
                <div className="height-25"></div>
            </div>
        </div>
    )
}

export default SectionHeading