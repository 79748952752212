import React from 'react'
import SectionHeading from "../SectionHeading/SectionHeading"
import smile_img from '../../../assets/images/smile.webp'
import sea_img from '../../../assets/images/sea.webp'
import about_img from '../../../assets/images/about-img.webp'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, EffectFade } from 'swiper/modules';
import './About.scss';

const About = () => {
    return (
        <section className='about section-pd' id="about">
            <div className="height-100"></div>
            <SectionHeading title="About Me" />
            <div className="container">
                <div className="block-wrap">
                    <div className="block-left">
                        <Swiper
                            loop={true}
                            spaceBetween={30}
                            effect={'fade'}
                            centeredSlides={true}
                            simulateTouch={false}
                            autoplay={{
                                delay: 10000,
                                disableOnInteraction: false,
                            }}
                            data-aos="fade-right" data-aos-duration="800" data-aos-delay="400"
                            modules={[Autoplay, EffectFade]}
                        >
                            <SwiperSlide >
                                <img src={smile_img} alt="slider-img" />
                            </SwiperSlide>
                            <SwiperSlide >
                                <img src={sea_img} alt="slider-img" />
                            </SwiperSlide>
                            <SwiperSlide >
                                <img src={about_img} alt="slider-img" />
                            </SwiperSlide>
                        </Swiper>
                    </div>
                    <div className="block-right">
                        <div className="about__text" data-aos="zoom-in" data-aos-duration="1000" data-aos-delay="500">
                            <h2 className="about__text-title">Hi There! I'm Roman Kalistratov</h2>
                            <h4 className="about__text-subtitle">Full-Stack Web Developer</h4>
                            <div className="about__text-description">
                                <p>Hi, I'm a Roman Kalistratov: a Full-Stack web developer, an amateur cook, a music enthusiast, and an overall curious individual.
                                    <br />In my work, I value serious and responsible individuals with a touch of recklessness :)
                                    My priorities are high-quality work and a satisfied client. I pay great attention to details when designing and developing my projects. My ultimate goal is to create software that is user-friendly and efficient in achieving the client's objectives.
                                    <br />When I'm not in front of pixels, I enjoy playing the guitar, listening to rock music, or tinkering with cars.
                                    <br />I'm looking forward to new projects and challenges. The best way to learn and improve is to take action, and I'm excited to continue developing my skills using new opportunities.</p>
                            </div>
                            <div className="about__btn">
                                <a className='btn' href='CV Roman Kalistratov.pdf' target="_blank">Download CV</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About
