import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import menuConfigs from '../../../configs/menu.configs'
import './SocialLinks.scss'

const SocialLinks = () => {
    const [activeLink, setActiveLink] = useState(0);
    const handleIconHover = (index) => {
        setActiveLink(index);
    };

    return (
        <div className="social-link">
            {menuConfigs.social.map((item, index) => (
                 <Link
                 to={item.path}
                 className={index === activeLink ? 'social-btn active' : 'social-btn'}
                 onMouseEnter={() => handleIconHover(index)}
                 target='_blank'
                 key={index}
               >
                 <span className="social-icon">{item.icon}</span>
                 <span className="icon-name">{item.display}</span>
               </Link>
            ))}
        </div>
    )
}

export default SocialLinks