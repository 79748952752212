import React, { useEffect, useState } from 'react'
import { MdKeyboardArrowUp } from "react-icons/md";
import './ScrollToTop.scss'

const ScrollToTop = () => {
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
      window.addEventListener("scroll", () => {
          if (window.scrollY > 70) {
              setIsShow(true);
          } else {
              setIsShow(false);
          }
      });
  }, []);

  const scrollTop = () => {
      window.scrollTo({
          top: 0,
          behavior: "smooth",
      });
  };


  return (
    <>
      {isShow && (
        <MdKeyboardArrowUp
          className={`scrollToTop`}
          onClick={scrollTop}
        />
      )}
    </>
  )
}

export default ScrollToTop