// import data from '../Data.json';
import { skillsData, resumeData, portfolioData } from '../Data';
import React from 'react';
import Hero from '../components/common/Hero/Hero';
import About from '../components/common/About/About';
import Skills from '../components/common/Skills/Skills';
import Resume from '../components/common/Resume/Resume';
import Portfolio from '../components/common/Portfolio/Portfolio';
import Contact from '../components/common/Contact/Contact';

const Home = () => {
  return (
    <>    
      <Hero />
      <About />
      <Skills data={skillsData} />
      <Resume data={resumeData} />
      <Portfolio data={portfolioData} />
      <Contact />
    </>
  )
}

export default Home