import React from 'react';
import { Link } from 'react-router-dom';

const SingleResume = ({ element }) => {

  return (
    <div className="resume__timeline">
      <h3 className="resume__timeline-title">{element?.title}</h3>
      <div className="resume__timeline-duration">{element?.duration} <span>{element?.hours}</span></div>
      <h4 className="resume__timeline-subtitle">
        <Link to={element?.link} target='_blank'>{element?.subTitle}</Link>
      </h4>
     
      <ul className="resume__timeline-description">
        {element?.text.map((descr, index) => (
          <li key={index}>{descr} </li>
      ))}</ul>
    </div>
  )
}

export default SingleResume;
