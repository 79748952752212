import React from 'react'
import "./Footer.scss"

const Footer = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();

    return (
        <footer>
            <div className="container">
                <div className="footer-wrap">
                    <div>{currentYear} © RK. All right reserved.</div>
                </div>
            </div>
        </footer>
    )
}

export default Footer