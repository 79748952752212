import React, { useRef, useState } from 'react'
import SectionHeading from "../SectionHeading/SectionHeading"
import emailjs from '@emailjs/browser';
import * as Yup from "yup";
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { MdWork, MdEmail, MdAlternateEmail, MdAccountCircle, MdMessage, MdLocationOn, MdTextSnippet } from 'react-icons/md';
import { FaSmileWink } from "react-icons/fa";
import SocialLinks from '../SocialLinks/SocialLinks';
import './Contact.scss'


const Contact = () => {
    const formRef = useRef();
    const [isSuccess, setIsSuccess] = useState(false);
    const [isRequest, setIsRequest] = useState(false);

    const contactForm = useFormik({
        initialValues: {
            name: "",
            company: "",
            email: '',
            subject: '',
            message: ''
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Invalid email format!").required("Email is required!"),
            message: Yup.string().required("Message is required!")
        }),
        onSubmit: async values => {
            // console.log(values);
            setIsRequest(true);

            emailjs.sendForm('service_fi14ljs', 'template_4bgty8f', formRef.current, 'Z3gokbFQ8de4ucw8T')
                .then((result) => {
                    setIsSuccess(true);
                    setIsRequest(false);
                    console.log(result);
                }, (error) => {
                    console.log(error);
                });
            contactForm.resetForm();
        },
    });


    return (
        <section className='contact' id='contact'>
            <div className="height-100"></div>
            <SectionHeading title="Contact Me" />
            <div className="container" data-aos="fade-up" data-aos-duration="800" data-aos-delay="500">
                <div className="block-wrap">
                    <div className="block-left">
                        <h3 className="contact__title">Just say Hello</h3>

                        <form ref={formRef} onSubmit={contactForm.handleSubmit} className='form'>
                            <div className="form__input-row">
                                <div className="form__input-box">
                                    <span><MdAccountCircle /></span>
                                    <input
                                        type="text"
                                        name="name"
                                        autoComplete="off"
                                        placeholder="your name"
                                        value={contactForm.values.name}
                                        onChange={contactForm.handleChange}
                                    />
                                </div>
                                <div className="form__input-box">
                                    <span><MdWork /></span>
                                    <input
                                        type="text"
                                        name="company"
                                        placeholder="your company"
                                        autoComplete="off"
                                        value={contactForm.values.company}
                                        onChange={contactForm.handleChange}
                                    />
                                </div>
                            </div>
                            <div className={`form__input-box ${contactForm.touched.email && contactForm.errors.email && "error"}`}>
                                <span><MdAlternateEmail /></span>
                                <input
                                    type="email"
                                    name="email"
                                    autoComplete="off"
                                    placeholder="your email"
                                    value={contactForm.values.email}
                                    onChange={contactForm.handleChange}
                                />
                            </div>
                            <div className="form__input-box">
                                <span><MdMessage /></span>
                                <input
                                    type="text"
                                    name="subject"
                                    autoComplete="off"
                                    placeholder="your subject"
                                    value={contactForm.values.subject}
                                    onChange={contactForm.handleChange}
                                />
                            </div>
                            <div className={`form__input-box ${contactForm.touched.message && contactForm.errors.message && "error"}`}>
                                <span><MdTextSnippet /></span>
                                <textarea
                                    type="text"
                                    name="message"
                                    autoComplete="off"
                                    placeholder="your message"
                                    cols="30"
                                    rows="3"
                                    value={contactForm.values.message}
                                    onChange={contactForm.handleChange}
                                />
                            </div>

                            <p className="message message__error">{
                                (contactForm.touched.email && contactForm.errors.email) ||
                                (contactForm.touched.message && contactForm.errors.message)}
                            </p>

                            {isSuccess && <p className="message message__success">Great! Your message has been sent. <br /> Have a nice day! <FaSmileWink /></p>}
                            {isRequest && <img className="form__loader" src="/loader.svg" alt="loader" />}

                            <button type='submit' className='btn'>Send Message</button>
                        </form>
                    </div>
                    <div className="block-right">
                        <h3 className="contact__title">Contact Info</h3>
                        <p className='contact__text'>If you wanna get in touch, talk to me about a project collaboration or just say hi,  fill up the form or send an email.<br/> Let's create something together.</p>
                        <div className="contact__info">
                            <div className="contact__info-wrap">
                                <div className="contact__info-icon">
                                    <MdEmail />
                                </div>
                                <div className="contact__info-details">
                                    <h4>Email</h4>
                                    <Link to="mailto:kalistratov.rk@gmail.com">kalistratov.rk@gmail.com</Link>
                                </div>
                            </div>
                            <div className="contact__info-wrap">
                                <div className="contact__info-icon">
                                    <MdLocationOn />
                                </div>
                                <div className="contact__info-details">
                                    <h4>Address</h4>
                                    <span>Akko, HaEshel 4, Israel</span>
                                </div>
                            </div>
                            <div className="contact__socialLinks">
                                <div className="contact__socialLinks-text">Visite my social profile and get connected</div>
                                <SocialLinks />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Contact