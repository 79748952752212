import React, { useEffect, useRef, useState } from "react";
import SectionHeading from "../SectionHeading/SectionHeading";
import { FaGithub, FaExternalLinkAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import "./Portfolio.scss";

const Portfolio = ({ data }) => {
  const { promo } = data;
  const itemsPerPage = 4;
  const [visibleItems, setVisibleItems] = useState(
    promo.slice(0, itemsPerPage)
  );
  const [showLoadMore, setShowLoadMore] = useState(false);

  useEffect(() => {
    promo.length > itemsPerPage
      ? setShowLoadMore(true)
      : setShowLoadMore(false);
  }, [promo]);

  const loadMoreItems = () => {
    const currentLength = visibleItems.length;
    const nextChunk = promo.slice(currentLength, currentLength + itemsPerPage);
    setVisibleItems((prevItems) => [...prevItems, ...nextChunk]);

    if (currentLength + itemsPerPage >= promo.length) {
      setShowLoadMore(false);
    }
  };

  return (
    <section className="portfolio" id="portfolio">
      <div className="height-100"></div>
      <SectionHeading title="Portfolio" />
      <div className="container">
        <div className="grid">
          {visibleItems.map((item, index) => (
            <figure
              key={index}
              className="effect-terry"
              data-aos={item.effect}
              data-aos-duration={item.duration}
              data-aos-delay={item.delay}
            >
              <img src={item.img} alt="promo-img" />
              <figcaption>
                <h2>
                  {item.title} <span>{item.span}</span>
                </h2>
                <Link className="details" to={`/portfolio/${item.projectName}`}>
                  Details
                </Link>
                <p>
                  <Link to={item.gitHubLink} target="_blank">
                    <FaGithub />
                  </Link>
                  <Link to={item.projectLink} target="_blank">
                    <FaExternalLinkAlt />
                  </Link>
                </p>
              </figcaption>
            </figure>
          ))}
        </div>
        {showLoadMore && (
          <div className="portfolio__loadMoreBtn">
            <button className="btn" onClick={loadMoreItems}>
              load more
            </button>
          </div>
        )}
      </div>
    </section>
  );
};

export default Portfolio;
