import { configureStore } from "@reduxjs/toolkit";
import navSidebarSlice from "./features/navSidebarSlice";
import appStateSlice from "./features/appStateSlice";
import hamburgerSlice from "./features/hamburgerSlice";

const store = configureStore({
  reducer: {
    navSidebar: navSidebarSlice,
    appState: appStateSlice,
    isHamburger: hamburgerSlice
  },
});

export default store;
